import { reactive } from 'vue';

import { Util } from "./util";
import { api } from "./api";
import { Cookies } from "./cookie";
import { User } from "./User";
import { FrontDb } from "./FrontDb";
import { auth } from "./auth";


/**
 * 
 * @typedef CardGuessContext
 * @prop {Object} card An Imges db card object.
 * @prop {Object} cardGuess cardGuess associated with the card object. 
 * 
 * 
 * @typedef RoundContext Contains all Deck/round objects of the current user.
 * @prop {Object} deck Deck object. 
 * @prop {Object} round The user round that belongs to the deck.
 * @prop {Array<CardGuessContext>} cardGuessContext All card objects that belong to the deck.
 * 
*/

/** @type {RoundContext[]} roundContexts */
export const roundContexts = reactive([]);



/** 
 * @typedef LoginContext Contains all information about currently logged in user
 * @prop {boolean} isLoggedIn Deck object. 
 * @prop {String} username Deck object. 
 * @prop {String} userUuid Deck object. 
 * @prop {number} isGuest Deck object. 
 * @prop {String} loginCookie Deck object. 
 * 
*/
export const loginContext = reactive({
        isLoggedIn: false,
        username: "",
        userUuid: "",
        isGuest: 1,
        loginCookie: "",
        progress: 0,
    });



export class Imges {


    /**
     * This is called on EVERY new rounting and page load.
     * Returns true if logged in. False if no login profile is found.
     * DOES NOT ALWAYS VALIDATE COOKIE. SESSION CACHE!
     * 
     * Checks:
     * 1. Check if login cookie exists
     * 2. Check user state,
     * 3. validate cookie
     * @returns boolean
     */
    static async isLoggedIn(){

        // 1. check if login cookie exists
        let loginCookie = Cookies.get("login"); 
        let useruuidCookie = Cookies.get("useruuid");

        // We can never be considered logged in if not both cookies exist
        if(!loginCookie || !useruuidCookie){
            return false;
        }


        // 2. State is saying logged in: return true -- same session as a validated cookie
        if(User.state.isLoggedIn){
            return true;
        }


        // 3. Test cookie agains api 
        // let cookieOk = await validateLoginCookie();
        let cookieOk = await auth.validateLoginCookie();
        if(cookieOk){
            User.state.isLoggedIn = true;
            return true;
        }


        return false;
    }



    
    /** 
     * Creates guest user and set user state
     * @returns {Promise<Boolean>} 
     */
    static async newRegularUser(newUsername, password){
        console.log("Imges.newRegularUser()")
        

        let postUserRequest = await api.users.post(newUsername, password, 0)
        
        // console.log("postUserRequest = ", postUserRequest)

        if(!postUserRequest){
            console.warn("New user creation failed. Staying on create user page.")
            return false;
        }

        // StateUser.setUser(postUserRequest);
        User.state.userUuid = postUserRequest.uuid;
        User.state.username = postUserRequest.name;
        User.state.isGuest = postUserRequest.isGuest;

        return postUserRequest;
        
    }



    /** NOT DONE */
    static async login(username, password){

        let loginPostOk = await api.login.post(username, password);
        if(!loginPostOk){
            console.log("loginPostOk Failed")
            return false;
        }

        User.state.loginCookie = Cookies.get("login");
        return true;
    }




    /** 
     * Creates guest user and set user state
     * @returns {Promise<Boolean>} 
     */
    static async newGuestUser(username){
        // console.log("Imges.newGuestUser()")
        

        let guestUsername;
        if(username){
            guestUsername = username;
        }
        else{
            guestUsername = Util.randomName();
        }

        // let newGuestRequest = await newUser(guestUsername, "", 1)
        let newGuestRequest = await api.users.post(guestUsername, "", 1)
        

        if(!newGuestRequest){
            console.warn("New guest user failed. Staying on home page. ")
            return null;
        }

        let loginCookie = Cookies.get("login"); 
        let useruuidCookie = Cookies.get("useruuid");
        User.state.userUuid = useruuidCookie;
        User.state.loginCookie = loginCookie;
        User.state.username = guestUsername;
        User.state.isGuest = 1;

        return 1;
        
    }


    /** 
     * Creates a round for each deck and an empty cardAnswer for each card/round. 
     * NOTE: forced to work directly with api as the frontdb does not play nice with its reactive vue state
     * 
     * @returns {Promise<Boolean>} 
     */
    static async createAllDeckRounds(_userUuid){
        // console.log("Imges.createUserAppData()")

        /**
         * @type {Array<DeckRound>} deckRounds
         */
        let deckRounds = [];

        let allDecks = await api.decks.getAll();
        if(!allDecks){
            console.log("Unable to get decks for user data creation. ")
            return false;
        }

        let allCards = await api.cards.getAll();
        console.log("allCards =", allCards)
        if(!allCards){
            console.log("Unable to get cards for user data creation. ")
            return false;
        }


        allDecks.forEach( async (_deck) => {

            let deckRound = {};
            deckRound.CardAndGuess = [];
            deckRound.deck = _deck;
            deckRound.round = await api.rounds.post(_userUuid, _deck.uuid);
            // console.log("deckRound.round =", deckRound.round)

            let _cards = allCards.filter(_card => _card.deckUuid === _deck.uuid)
            // console.log("_cards =", _cards)

            _cards.forEach(async (_card) => {
                let cardAndGuess = {};
                cardAndGuess.card = _card;
                cardAndGuess.cardGuess = await api.cardGuess.post(deckRound.round.uuid, _card.uuid);
                deckRound.CardAndGuess.push(cardAndGuess);
            })
            
            deckRounds.push(deckRound);

        })


        return deckRounds;
    }




    /** 
     * Loads all data
     * NOT DONE --- NEW TO CHECK IF LOGGED IN??
     * @returns {Promise<Boolean>} 
     */
    static async loadAppData(){
        // console.log("Imges.loadAppData()")
        try {
            await FrontDb.loadDeckCards();
            await FrontDb.loadRoundsGuesses(FrontDb.state.user.getUseruuid());
        } catch (error) {
            console.error(error);
            return 0;
        }
        return 1;
    }

}


