
import { reactive } from 'vue';

import { newUser, validateLoginCookie } from './api';
import { Cookies } from './cookie';





export class User {

    /** 
     * 
     * @typedef LoginContext Contains all information about currently logged in user
     * @prop {boolean} isLoggedIn isLoggedIn 
     * @prop {String} username username
     * @prop {String} userUuid userUuid
     * @prop {number} isGuest isGuest
     * @prop {String} loginCookie loginCookie. 
     * @prop {String} progress test. 
     * 
     */
    static state = reactive({
        isLoggedIn: false,
        username: "",
        userUuid: "",
        isGuest: 1,
        loginCookie: "",
        progress: 0,
    });



    static setUsername(name) {
        this.state.username = name;
    }
    static getUsername() {
        return this.state.username;
    }

    static getUseruuid() {
        return this.state.userUuid;
    }

    static clearState(){
        this.state.isLoggedIn = false;
        this.state.userUuid = "";
        this.state.loginCookie = "";
        this.state.username = "";
        this.state.isGuest = true;
    }

    static async newGuest(){
        console.log("NEW GUEST!")

        let newUsername = "guest-" + Math.floor(Math.random() * 10000);
        let _newUser = await newUser(newUsername, "", 1)
        if(!_newUser){
            console.warn("New guest user failed. Staying on home page. ")
            return;
        }

        let loginCookie = Cookies.get("login"); 
        let useruuidCookie = Cookies.get("useruuid");
        this.state.userUuid = useruuidCookie;
        this.state.loginCookie = loginCookie;
        this.state.username = newUsername;
        this.state.isGuest = 1;

        return 1;
    }

    static setUser(userRow){
        this.state.userUuid = userRow.uuid;
        this.state.username = userRow.name;
        this.state.isGuest = userRow.isGuest;

        this.state.loginCookie = Cookies.get("login");
    }


    static async login(){
        console.log("NEW GUEST!")

        let newUsername = "guest-" + Math.floor(Math.random() * 10000);
        let _newUser = newUser(newUsername, "", 1)
        if(!_newUser){
            console.warn("New guest user failed. Staying on home page. ")
            return;
        }

        let loginCookie = Cookies.get("login"); 
        let useruuidCookie = Cookies.get("useruuid");
        this.state.userUuid = useruuidCookie;
        this.state.loginCookie = loginCookie;
        this.state.username = newUsername;
        this.state.isGuest = 1;

    }


    /**
     * This is called on EVERY new rounting and page load.
     * Returns true if logged in. False if no login profile is found.
     * DOES NOT ALWAYS VALIDATE COOKIE. SESSION CACHE!
     * 
     * Checks:
     * 1. Check if login cookie exists
     * 2. Check user state,
     * 3. validate cookie
     * @returns boolean
     */
    static async isLoggedIn(){

        // 1. check if login cookie exists
        let loginCookie = Cookies.get("login"); 
        let useruuidCookie = Cookies.get("useruuid");

        // We can never be considered logged in if not both cookies exist
        if(!loginCookie || !useruuidCookie){
            return false;
        }


        // 2. State is saying logged in: return true -- same session as a validated cookie
        if(this.state.isLoggedIn){
            return true;
        }


        // 3. Test cookie agains api 
        let cookieOk = await validateLoginCookie();
        if(cookieOk){
            this.state.isLoggedIn = true;
            return true;
        }


        return false;
    }

    static logout(){
        if(User.isLoggedIn){
            return true;
        }
        
    }

    static updateProgress(newProgress) {
        this.state.progress = newProgress;
    }

}





export class StateRound {
    // Use a reactive object to make the state reactive in Vue
    static state = reactive({
        useruuid: "",
        deckuuid: "",
        loginCookie: "",
        progress: 0,
    });


    static setDeckuuidFromqueryparams(){
        const urlParams = new URLSearchParams(window.location.search);
        this.state.deckuuid = urlParams.get("deckuuid");
    }
}


