
import { reactive } from "vue";

/**
 * 
 * @typedef CardGuessContext
 * @prop {Object} card An Imges db card object.
 * @prop {Object} cardGuess cardGuess associated with the card object. 
 * 
 * 
 * @typedef RoundContext Contains all Deck/round objects of the current user.
 * @prop {Object} deck Deck object. 
 * @prop {Object} round The user round that belongs to the deck.
 * @prop {Array<CardGuessContext>} cardGuessContext All card objects that belong to the deck.
 * 
*/

/** @type {RoundContext[]} RoundContexts */
export const RoundContexts = reactive([]);


/**
 * 
 * @typedef CardImageContext Contains all image/canvas objects for gamplay/rendering
 * @prop {Object} card Card object. 
 * @prop {Object} image Image element. 
 * @prop {Object} imageUrl The URL of the already fetched image file.
 * @prop {number} imageHeight Height of original image
 * @prop {number} imageWidth Width of original image
 * @prop {number} imageHWRatio imageHeight / imageWidth
 * @prop {Object} canvasElement All card objects that belong to the deck.
 * 
*/

/** @type {CardImageContext[]} deckImageContext */
export const CardImageContexts = reactive([]);

/**
 * 
 */
export let RoundImages = reactive({
    imageMap: new Map(),
})


export class Rounds {

    /**
     * Empties the RoundContext reactive array and populates with passed roundContexts.
     * @param {RoundContext[]} _roundContexts 
     */
    static load(_roundContexts){
        while(RoundContexts.length > 0){
            RoundContexts.pop()
        }

        for(const _roundContext of _roundContexts){
            RoundContexts.push(_roundContext)
        }
    }

    static async loadCardImageContext(card){
        let cardImageContext = {};
        cardImageContext.card = card;

        // fetch image
        let apiPath = `/api/images/${card.cardImageUuid}?file=true`;
        let imgRequest = await fetch(apiPath);
        let imgBlob = await imgRequest.blob();
        let imgUrlObject = URL.createObjectURL(imgBlob)
        cardImageContext.imageUrl = imgUrlObject;

        // Image and Its Dimensions
        // const image = new Image();
        // image.src = imgUrlObject;
        cardImageContext.image = new Image();
        cardImageContext.image.src = imgUrlObject;

        await new Promise((resolve) => {
            cardImageContext.image.onload = resolve; // Wait for the image to load
        });
        console.log("image loaded");
        

        const width = cardImageContext.image.width;
        const height = cardImageContext.image.height; 
        cardImageContext.imageHeight = height; 
        cardImageContext.imageWidth = width;
        cardImageContext.imageHWRatio = height/width;
        console.log("cardImageContext.imageHWRatio =", cardImageContext.imageHWRatio);
        
        RoundImages.imageMap.set(card.uuid, imgUrlObject);

        CardImageContexts.push(cardImageContext);
    }

    static getCardImageContext(carduuid){
        return CardImageContexts.find(_cardImageContext => _cardImageContext.card.uuid === carduuid)
    }

    // static async loadImageIntoMap(carduuid, imageuuid){

    //     let apiPath = `/api/images/${imageuuid}?file=true`;
    //     let imgRequest = await fetch(apiPath);
    //     let imgBlob = await imgRequest.blob();
    //     let imgUrlObject = URL.createObjectURL(imgBlob)

    //     // let imgFile = new File(imgBlob, "fileName.test")
    //     console.log("imgUrlObject =", imgUrlObject);
        
    //     RoundImages.imageMap.set(carduuid, imgUrlObject);
    // }
    // static clearImageMap(){
    //     // TODO : Make sure url objects are released when deck is done using "URL.revokeObjectURL(...)"
    // }
    
    static cardsAnswered(_rounduuid){

        let roundContext = RoundContexts.find(_roundContext => _roundContext.round?.uuid === _rounduuid);

        if(!roundContext){
            return null;
        }

        let answeredCount = 0;
        for(const cardGuessContext of roundContext.cardGuessContexts){
            if(cardGuessContext.cardGuess.is_done){
                answeredCount++;
            }
        }
        return answeredCount;
    }

}