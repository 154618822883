

<script setup>

// import { ref } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { ref } from 'vue';
// import { useRouter } from 'vue-router';

// import { baseApiUrl, newRound } from '@/modules/api';

import DeckInfo from './DeckInfo.vue';
// import RoundInfo from './RoundInfo.vue';

import { User } from '@/modules/User';
import RoundInfo from './RoundInfo.vue';

// import { FrontDb } from '@/modules/FrontDb';



import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { api } from '@/modules/api';
import { CardImageContexts, RoundContexts, RoundImages, Rounds } from '@/modules/Rounds';
const router = useRouter();
const route = useRoute();
const deckuuid = route.query.deckuuid;
const rounduuid = route.query.rounduuid;
console.log("deckuuid =", deckuuid)
console.log("rounduuid =", rounduuid)


let roundContext = RoundContexts.find(_roundContext => _roundContext.deck?.uuid === deckuuid);

// Load images
const imagesLoaded = ref(0);
RoundImages.imageMap.clear();
console.log("RoundImages.imageMap =", RoundImages.imageMap);


// If round is not completed we load all images
// if(!roundContext.round.completed){
    let cardImageContextRequests = [];
    while (CardImageContexts.length > 0) {
        CardImageContexts.pop();
    }
    for(const cardGuessContext of roundContext.cardGuessContexts){
        const imageContextRequest = Rounds.loadCardImageContext(cardGuessContext.card);
        cardImageContextRequests.push(imageContextRequest)
    }
    // Trigger ref when all images are fetched
    Promise.all(cardImageContextRequests).then(() => {
        console.log("ALL IMAGES LAODED");
        console.log("RoundImages.imageMap =", RoundImages.imageMap);
        
        imagesLoaded.value = 1;
        // console.log("CardImageContexts =", CardImageContexts);
    })
// }


// Used to determine which card to move to
let completedCardCount = 0;
for(let i = 0; i < roundContext.cardGuessContexts.length; i++){
    if(roundContext.cardGuessContexts[i].cardGuess?.is_done){
        completedCardCount++;
    }
}
// console.log("completedCardCount =", completedCardCount);






onMounted(() => {
})


// function hello(){
//     console.log("HEHELHEOOEHHO")
//     router.push(`/round-view?rounduuid=round-round&cardindex=1`)
// }

// function createRound(){
//     console.log("CREATING ROUND")
//     // console.log("deckuuid =", _deckRound.deck.uuid)
//     // console.log("useruuid =", StateUser.getUseruuid())

//     FrontDb.newRound(_deckRound.deck.uuid);

// }

async function continueRound(){
    console.log("CONTINUING ROUND")
    console.log("rounduuid =", roundContext.round.uuid)


    let nextRoute = "/round-play?" + `rounduuid=${roundContext.round.uuid}` + `&cardindex=${completedCardCount}`;
    router.push(nextRoute)

}

async function startRound(){
    console.log("STARTING ROUND")
    console.log("rounduuid =", roundContext.round.uuid)

    api.rounds.patch(roundContext.round.uuid, {
            "started": 1
    })

    let firstRoute = "/round-play?" + `rounduuid=${roundContext.round.uuid}` + `&cardindex=0`;
    // console.log("firstRoute =", firstRoute);
    
    router.push(firstRoute)

}

function backToDeckList(){
    router.push("/deck-list");
}





// let completedCardCount = _deckRound.cardAndGuess[cardindex].card.cardImageUuid

const selectedRoundContext = computed(() =>
    // FrontDb.state.deckRounds.find(deckround => deckround.deck?.uuid === deckuuid)
    RoundContexts.find(_roundContext => _roundContext.deck?.uuid === deckuuid)
);
// const selectedRound = computed(() =>
//     FrontDb.state.deckRounds.find(deckround => deckround.round?.uuid === rounduuid)
// );

function viewResults(){
    let roundResultsRoute = `/round-results?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`
    router.push(roundResultsRoute)
}

</script>



<template>
    <div class="DeckOverview">
        
        <div @click="backToDeckList" class="bg-gray-500 rounded-md w-1/4 p-2 hover:cursor-pointer">BACK</div>
        <h1>ROUND VIEW</h1>

        <!-- <div v-if="FrontDb.state.deckRounds.length > 0"> -->
        <!-- <div v-if="FrontDb.state.deckRounds[0]"> -->
        <!-- <div v-if="loading"> -->
            <!-- <p> {{ FrontDb.state }}</p> -->

            <DeckInfo :deck="selectedRoundContext.deck" />

            <RoundInfo :round="selectedRoundContext.round" :cardGuessContexts="selectedRoundContext.cardGuessContexts"/>

            <div v-if="!roundContext.round.completed && !imagesLoaded" id="start-deck-btn" class="bg-gray-200">
                LOADING IMAGES 
            </div>
            <div v-else-if="selectedRoundContext.round?.started === 0" id="start-deck-btn" class="orange" @click="startRound">
                START 
            </div>
            <div v-else-if="selectedRoundContext.round?.started === 1 && selectedRoundContext.round?.completed === 0" id="start-deck-btn" class="orange" @click="continueRound">
                CONTINUE ROUND
            </div>


            <div v-if="roundContext.round.completed" @click="viewResults" id="start-deck-btn" class="green">
                VIEW RESULTS
            </div>


            <!-- <div v-if="imagesLoaded">
                Images Loaded
            </div>
            <div v-else>
                loading Images
            </div> -->
<!-- 
        </div>
        <div v-else>
            state not ready : RoundView.vue
        </div> -->
        
    
    </div>
</template>



<script>


export default {
    name: "DeckOverview",
    methods: {
        async start_deck(){
            // const router = useRouter();
            

            console.log("START DECK CLICKED");
            let _isLoggedIn = User.isLoggedIn();
            if(!_isLoggedIn){
                console.error("UNABLE TO CREATE NEW ROUND");
                return;
            }

            console.log("IS LOGGED IN");

            console.log("window.location.pathname =", window.location.search)
            const urlParams = new URLSearchParams(window.location.search);
            console.log("urlParams.get(\"deckuuid\") =", urlParams.get("deckuuid"))
            // const _deckuuid = urlParams.get("deckuuid");
            // const _useruuid = StateUser.getUseruuid();

            // console.log(_deckuuid + _useruuid)
            
            // let _newRound = await newRound(_deckuuid, _useruuid);
            // console.log("newRound =", _newRound)
            
            console.log("NEW ROUND !  SENDING TO FIRST CARD!")
            // router.push("/round-play");
            this.$router.push('/round-play');
            
        }
    }
};

</script>





<style>
.DeckOverview {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

#start-deck-btn {
    padding: 10px;
}
#start-deck-btn:hover {
    cursor: pointer;
}
</style>