<template>
  <form @submit.prevent="handleSubmit" class="new-user-form center">
    <div class="form-group">
      <label for="username">Username</label>
      <input
        id="username"
        v-model="formData.username"
        type="text"
        value="u"
        required
      />
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <input
        id="password"
        v-model="formData.password"
        type="password"
        value="p"
        required
      />
    </div>

    <div class="form-group">
      <label for="confirmPassword">Confirm Password</label>
      <input
        id="confirmPassword"
        v-model="formData.confirmPassword"
        type="password"
        value="p"
        required
      />
      <p v-if="passwordsDoNotMatch" class="error-message">
        Passwords do not match
      </p>
    </div>

    <button
      type="submit"
      :disabled="!isFormValid"
    >
      Create Account
    </button>
  </form>
</template>




<script>
// import { onUpdated } from 'vue';

// import { baseApiUrl } from '@/modules/api';

import { autoFillInputs, testData } from '@/dev/CreateUser-dev';
import { FrontDb } from '@/modules/FrontDb';
import { Imges } from '@/modules/Imges';

import { User } from '@/modules/User';

autoFillInputs(testData.selectors, testData.values);



export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
        confirmPassword: ''
      }
    }
  },
  computed: {
    passwordsDoNotMatch() {
      return this.formData.password && 
             this.formData.confirmPassword && 
             this.formData.password !== this.formData.confirmPassword
    },
    isFormValid() {
      return this.formData.username && 
             this.formData.password && 
             this.formData.confirmPassword && 
             !this.passwordsDoNotMatch
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isFormValid) {

        let newUserObject = await Imges.newRegularUser(this.formData.username, this.formData.password);
        if(!newUserObject){
            console.error("New user creation failed")
            return;
        }

        let loggedInOk = await Imges.login(this.formData.username, this.formData.password);
        if(!loggedInOk){
            console.log("Failed to login new user.")
            return;
        }

        let isLoggedIn = await Imges.isLoggedIn();
        if(!isLoggedIn){
            console.log("Failed to verify login for new user.")
            return;
        }
        
        let allDeckRounds = await Imges.createAllDeckRounds(User.state.userUuid);
        if(!allDeckRounds){
            console.log("DATA NOT CREATED OK FOR NEW GUEST")
            return;
        }

        while(FrontDb.state.deckRounds.length > 0){
            FrontDb.state.deckRounds.pop();
        }

        let dataLoadedOk = await Imges.loadAppData();
        if(!dataLoadedOk){
            console.log("Data not loaded ok for new user")
            return;
        }

        console.log("NEW USER CHECKS PASSED. MOVING TO DASHBOARD.")

        this.$router.push('/dashboard-user');
        return;

      }
    }
  }
}
</script>

<style scoped>
.new-user-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;

  height: 100%;
  
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}
 
input {
  width: 100%;

  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 3px rgba(74, 144, 226, 0.3);
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #357abd;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}
</style>