import { api } from "./api";

class Auth {

    
    async validateLoginCookie(){

        try {
            await api.login.get();
            return true;

        } catch (err) {
            console.error(err.message);
            return false;
        } 
    }
}

export const auth = new Auth();