<script setup>

import { useRouter } from 'vue-router';

import DeckListItem from './DeckListItem.vue';
// import { FrontDb } from '@/modules/FrontDb';

import { RoundContexts } from '@/modules/Rounds';

const router = useRouter();



function backToDeckList(){
    router.push("/dashboard-user");
}


</script>



<template>

    <!-- <LoadingScreen />  -->

    <div class="deck-list-container">
        <div @click="backToDeckList" class="bg-gray-500 rounded-md w-1/4 p-2 hover:cursor-pointer">BACK</div>
        <h1 class="text-xl text-black font-bold m-3">CHOOSE CATEGORY</h1>

        <!-- <div class="scrollable-list center">
            <DeckListItem v-for="deck in decks" :key="deck.uuid" :name="deck.name" :uuid="deck.uuid" :deck="deck" />
        </div> -->


        <!-- <div v-if="FrontDb.state.deckRounds.length > 0" class="scrollable-list center">
                <div v-for="(deckRound, index) in FrontDb.state.deckRounds" :key="index" class="w-100 center">

                    <DeckListItem v-if="deckRound.round && deckRound.deck" :name="deckRound.deck.name" :uuid="deckRound.deck.uuid" :deck="deckRound.deck" :round="deckRound.round" />
                    <div v-else-if="deckRound.deck"> deckRound.round missing</div>
                    <div v-else-if="deckRound.round"> deckRound.deck missing</div>
                    <div v-else> Unable to display decks. deckRound.deck AND deckRound.round missing</div>
                </div>
        </div> -->

        <div v-if="RoundContexts.length > 0" class="scrollable-list center">
                <div v-for="(roundContext, index) in RoundContexts" :key="index" class="w-100 center">

                    <DeckListItem v-if="roundContext.round && roundContext.deck" :name="roundContext.deck.name" :uuid="roundContext.deck.uuid" :deck="roundContext.deck" :round="roundContext.round" />
                    <div v-else-if="roundContext.deck"> deckRound.round missing</div>
                    <div v-else-if="roundContext.round"> deckRound.deck missing</div>
                    <div v-else> Unable to display decks. deckRound.deck AND deckRound.round missing</div>
                </div>
                <!-- {{ FrontDb.state.deckRounds[1] }} -->
        </div>
        <div v-else>
            LOADING
        </div>

        

    </div>

</template>




<style scoped>

.deck-list-container {
    height: 100%;
    width: 100%;
}

.scrollable-list {
    border: solid rgb(171, 171, 171) 1px;
    height: 100%;
    width: 100%;
    justify-content: start;
}



</style>
