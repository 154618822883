
// export const baseApiUrl = "http://localhost:8788/api";
const baseApiUrl = window.location.origin + "/api";
// export const baseApiUrl = "https://imges.anderserik.com/api";
// console.log("API BASE TEST = ", window.location.origin + "/api")

// console.log("process.env.API_BASE_URL =", process.env.API_BASE_URL)

/**
 * 
 * @typedef CardGuessContext
 * @prop {Object} card An Imges db card object.
 * @prop {Object} cardGuess cardGuess associated with the card object. 
 * 
 * 
 * @typedef RoundContext Contains all Deck/round objects of the current user.
 * @prop {Object} deck Deck object. 
 * @prop {Object} round The user round that belongs to the deck.
 * @prop {Array<CardGuessContext>} cardGuessContext All card objects that belong to the deck.
 * 
 */


class Api {

    // users = UsersApi;
    // decks = DecksApi;
    // cards = CardsApi;
    // rounds = RoundApi;
    // cardGuess = CardGuessApi;

    constructor() {
        this.login = LoginApi;
        this.users = UsersApi;
        this.decks = DecksApi;
        this.cards = CardsApi;
        this.rounds = RoundApi;
        this.cardGuess = CardGuessApi;
        this.roundcontext = RoundContextsApi;
        this.highscore = HighscoreApi;
    }

    // decks = new DecksApi();

}

/**
 * @typedef HighscoreRow
 * @property {string} name
 * @property {number} totalTimeMs
 * 
 * @typedef HighscoreObject
 * @type {object}
 * @property {HighscoreRow[]} highscore - an ID.
 * @property {string} userpos - your name.
 */

class HighscoreApi {

    /** @returns {Promise<HighscoreObject>} */
    static async getHighscore(deckuuid, username, timespan = 1){
            

        let request = await fetch(baseApiUrl + "/highscore?" + `deckuuid=${deckuuid}&` + `useruuid=${username}&` + `timespan=${timespan}`);

        if(!request.status) {
            console.log("highscore request failed");
            throw new Error("highscore request failed");
        }

        let highscore = await request.json();
        // console.log("cardsJson =", cardsJson)
        
        return highscore;

    }
    
}





class RoundContextsApi {

    /** @returns {Promise<RoundContext[]>} */
    static async getForUser(useruuid){

        let request = await fetch(baseApiUrl + "/roundcontexts?" + `useruuid=${useruuid}`);

        if(!request.status) {
            console.log("roundcontextsRequest failed");
            throw new Error("roundcontextsRequest failed");
        }

        let roundcontexts = await request.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundcontexts;

    }

    /** @returns {Promise<RoundContext[]>} */
    static async postForUser(useruuid){

        let request = await fetch(
            baseApiUrl + "/roundcontexts?" + `useruuid=${useruuid}`, 
            {
                method: "POST"
            }
        );
        

        if(!request.status) {
            console.log("RoundContext post failed");
            throw new Error("RoundContext post failed");
        }

        let roundcontexts = await request.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundcontexts;

    }

    
}


class LoginApi {

    /** @returns {Promise<Boolean>} */
    static async get(){
        try {
        // If registration successful, proceed with login
        const loginCookieValidation = await fetch(baseApiUrl + '/login', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!loginCookieValidation.ok) {
            // alert("Login Failed\n" + `${await loginCookieValidation.json()}`)
            throw new Error('Login cookie validation failed. Please try logging in using user credentials.')
        }

        return 1; 


      } catch (err) {
        console.error(err.message);
        return 0;
      } 

    }

    static async post(_userName, _password){
        try {
        // If registration successful, proceed with login
        const postLoginRequest = await fetch(baseApiUrl + '/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: _userName,
            password: _password,
          })
        })

        if (!postLoginRequest.ok) {
            alert("Login Failed\n" + `${await postLoginRequest.json()}`)
            throw new Error('Logout failed.')
        }

        return true;


      } catch (err) {
        console.error(err.message);
        return false;
      }
    }


    static async logout(){

        const logoutRequest = await fetch(baseApiUrl + '/login', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!logoutRequest.ok) {
            alert("Logout Failed\n" + `${await logoutRequest.body.json()}`)
            return false;
        }


        return true;
    }

}


class UsersApi {

    /** @returns {Promise<Array>} */
    static async getAll(){

        let usersRequest = await fetch(baseApiUrl + "/users");
        

        if(!usersRequest.status) {
            console.log("usersRequest failed");
            throw new Error("usersRequest failed");
        }

        let usersJson = await usersRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return usersJson;

    }

    

    /** @returns {Promise<Object>} */
    static async post(_userName, _password, _isGuest){

        try {

            // If registration successful, proceed with login
            const newUserRequest = await fetch(baseApiUrl + '/users', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                name: _userName,
                password: _password,
                isGuest: _isGuest,
                })
            })

            if (!newUserRequest.ok) {
                alert("New User Failed\n" + `${await newUserRequest.json()}`)
                throw new Error('Logout failed.')
            }

            return (await newUserRequest.json())[0];

        } catch (err) {

            console.error(err.message);
            return false;
        }

    }

    /** @returns {Promise<Array>} */
    static async get(_userUuid){
        try {
        // If registration successful, proceed with login
        const userRequest = await fetch(baseApiUrl + '/users/' + _userUuid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!userRequest.ok) {
            alert("Unable to get user\n" + `${await userRequest.json()}`)
            throw new Error('Logout failed.')
        }


        return (await userRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      }

    }
    
}


class DecksApi {

    /** @returns {Promise<Array>} */
    static async getAll(){
        let decksRequest = await fetch(baseApiUrl + "/decks");
        

        if(!decksRequest.status) {
            console.log("decksRequest failed");
            throw new Error("decksRequest failed");
        }

        let decksJson = await decksRequest.json();
        
        return decksJson;

    }

}

class CardsApi {

    /** @returns {Promise<Array>} */
    static async getAll(){
        let cardsRequest = await fetch(baseApiUrl + "/cards");
        

        if(!cardsRequest.status) {
            console.log("cardsRequest failed");
            throw new Error("cardsRequest failed");
        }

        let cardsJson = await cardsRequest.json();
        
        return cardsJson;

    }

    /** @returns {Promise<Array>} */
    static async getCardsOfDeck(_deckUuid){
        
        let cardsRequest = await fetch(baseApiUrl + "/cards" + `?deckuuid=${_deckUuid}`);
        

        if(!cardsRequest.status) {
            console.log("cardsRequest failed");
            throw new Error("cardsRequest failed");
        }

        let cardsJson = await cardsRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return cardsJson;

    }
}


class RoundApi {

    /** @returns {Promise<Array>} */
    static async getAll(){

        let roundsRequest = await fetch(baseApiUrl + "/rounds");
        

        if(!roundsRequest.status) {
            console.log("roundsRequest failed");
            throw new Error("roundsRequest failed");
        }

        let roundsJson = await roundsRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundsJson;

    }

    /** @returns {Promise<Object>} */
    static async post(_useruuid, _deckuuid){

        let postRequest = await fetch(
            baseApiUrl + "/rounds", {
                method: "POST",
                body: JSON.stringify({
                    userUuid: _useruuid,
                    deckUuid: _deckuuid,
                })
            }
        );
        

        if(!postRequest.status) {
            console.log("Round postRequest failed");
            throw new Error("Round postRequest failed");
        }

        let roundJson = await postRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundJson[0];

    }

    /** @returns {Promise<Object>} */
    static async patch(uuid, patchBody){

        let patchRequest = await fetch(
            baseApiUrl + "/rounds/" + `${uuid}`, {
                method: "PATCH",
                body: JSON.stringify(patchBody)
            }
        );
        

        if(!patchRequest.status) {
            console.log("Round postRequest failed");
            throw new Error("Round postRequest failed");
        }

        let roundJson = await patchRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundJson;

    }

    /** @returns {Promise<Array>} */
    static async getRoundsOfUser(_userUuid){
        let roundsRequest = await fetch(baseApiUrl + "/rounds" + `?useruuid=${_userUuid}`);
        

        if(!roundsRequest.status) {
            console.log("roundsRequest failed");
            throw new Error("roundsRequest failed");
        }

        let roundsJson = await roundsRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return roundsJson;

    }
    
}

class CardGuessApi {

    /** @returns {Promise<Array>} */
    static async getAll(){
        
        let guessRequest = await fetch(baseApiUrl + "/cardguess");
        

        if(!guessRequest.status) {
            console.log("guessRequest failed");
            throw new Error("guessRequest failed");
        }

        let guessJson = await guessRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return guessJson;

    }

    /** @returns {Promise<Object>} */
    static async post(_rounduuid, _carduuid){

        let postRequest = await fetch(
            baseApiUrl + "/cardguess", {
                method: "POST",
                body: JSON.stringify({
                    rounduuid: _rounduuid,
                    carduuid: _carduuid,
                })
            }
        );
        

        if(!postRequest.status) {
            console.log("Round postRequest failed");
            throw new Error("Round postRequest failed");
        }

        let cardGuessJson = await postRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return cardGuessJson;

    }

    /** @returns {Promise<Object>} */
    static async patch(uuid, patchBody){

        let patchRequest = await fetch(
            baseApiUrl + "/cardguess/" + `${uuid}`, {
                method: "PATCH",
                body: JSON.stringify(patchBody)
            }
        );
        

        if(!patchRequest.status) {
            console.log("Round postRequest failed");
            throw new Error("Round postRequest failed");
        }

        let cardGuessJson = await patchRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return cardGuessJson;

    }

    /** @returns {Promise<Array>} */
    static async getGuessesOfRound(_roundUuid){
        let guessRequest = await fetch(baseApiUrl + "/cardguess" + `?rounduuid=${_roundUuid}`);
        

        if(!guessRequest.status) {
            console.log("guessRequest failed");
            throw new Error("guessRequest failed");
        }

        let guessJson = await guessRequest.json();
        // console.log("cardsJson =", cardsJson)
        
        return guessJson;

    }
    
}


export const api = new Api();

export async function roundInfo(){

   
}


export async function newRound(_deckuuid, _useruuid){
    
    try {
        // If registration successful, proceed with login
        const newRoundRequest = await fetch(baseApiUrl + '/rounds?' +`deckUuid=${_deckuuid}` + `&userUuid=${_useruuid}` ,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deckUuid: _deckuuid,
            userUuid: _useruuid,
          })
        })

        if (!newRoundRequest.ok) {
            // alert("New Round Request Failed\n" + `${await newRoundRequest.json()[0]}`)
            throw new Error('New Round failed.')
        }

        console.log("New Round OK")
        

        return (await newRoundRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }

}


export async function getUser(uuid){
    try {
        // If registration successful, proceed with login
        const userRequest = await fetch(baseApiUrl + '/users/' + uuid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!userRequest.ok) {
            alert("Unable to get user\n" + `${await userRequest.json()}`)
            throw new Error('Logout failed.')
        }

        // console.log("Got User")
        

        return (await userRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }

}


export async function newUser(_userName, _password, _isGuest){

    try {
        // If registration successful, proceed with login
        const newUserRequest = await fetch(baseApiUrl + '/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: _userName,
            password: _password,
            isGuest: _isGuest,
          })
        })

        if (!newUserRequest.ok) {
            alert("New User Failed\n" + `${await newUserRequest.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("New User OK")
        

        return (await newUserRequest.json())[0];


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}

export async function login(_userName, _password){

    try {
        // If registration successful, proceed with login
        const newUserRequest = await fetch(baseApiUrl + '/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: _userName,
            password: _password,
          })
        })

        if (!newUserRequest.ok) {
            alert("Login Failed\n" + `${await newUserRequest.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("New User OK")

        return true;


      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}



export async function logout(){

    try {
        // If registration successful, proceed with login
        const logoutRequest = await fetch(baseApiUrl + '/login', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!logoutRequest.ok) {
            alert("Logout Failed\n" + `${await logoutRequest.body.json()}`)
            throw new Error('Logout failed.')
        }

        console.log("LOGOUT OK")

        return true;

        // If both registration and login are successful, redirect to dashboard
        // window.location.href = '/dashboard'
        // alert("Login succefull!!")
        // this.$router.push('/dashboard-user');

      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}


 
export async function validateLoginCookie(){

    try {
        // If registration successful, proceed with login
        const loginCookieValidation = await fetch(baseApiUrl + '/login', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (!loginCookieValidation.ok) {
            // alert("Login Failed\n" + `${await loginCookieValidation.json()}`)
            throw new Error('Login cookie validation failed. Please try logging in using user credentials.')
        }

        console.log("COOKIE OK")

        return true; 

        // If both registration and login are successful, redirect to dashboard
        // window.location.href = '/dashboard'
        // alert("Login succefull!!")
        // this.$router.push('/dashboard-user');

      } catch (err) {
        console.error(err.message);
        return false;
      } finally {
        // Removed
      }
}




export async function wait(timeMs){
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve("Success!"); // Yay! Everything went well!
        }, timeMs);
        // reject();
    })
}