<script setup>
import { defineProps } from 'vue';
// import { StateUser } from '@/modules/StateUser';
// import { FrontDb } from '@/modules/front-db';

const props = defineProps({
    deck: Object,
    round: Object,
})

console.log("props = ", props.deck)

</script>




<template>
    <!-- card and round exists for user -->
     <div v-if="deck && round && round.completed === 1" class="deck-list-item bg-gray-500 hover:bg-gray-700 center">
        <router-link :to="{ path: '/round-view', query: { rounduuid: round.uuid, deckuuid: deck.uuid  } }">
            <div :data-uuid="deck.uuid">
                {{ deck.name }} <br>
                
                
            </div>
            <!-- <p class="text-sm">
                Round Exists<br> 
                started: {{ round.started }}  <br>
                completed: {{ round.completed }}
            </p> -->
        </router-link>
    </div>

    <div v-else-if="deck && round && round.started === 1" class="deck-list-item bg-orange-500 hover:bg-orange-700 border-4 border-black center">
        <router-link :to="{ path: '/round-view', query: { rounduuid: round.uuid, deckuuid: deck.uuid  } }">
            <div :data-uuid="deck.uuid">
                {{ deck.name }} <br>
                
                
            </div>
            <!-- <p class="text-sm">
                Round Exists<br> 
                started: {{ round.started }}  <br>
                completed: {{ round.completed }}
            </p> -->
        </router-link>
    </div>

    <div v-else-if="deck && round" class="deck-list-item bg-orange-500 hover:bg-orange-700 center">
        <router-link :to="{ path: '/round-view', query: { rounduuid: round.uuid, deckuuid: deck.uuid  } }">
            <div :data-uuid="deck.uuid">
                {{ deck.name }} <br>
                
                
            </div>
            <!-- <p class="text-sm">
                Round Exists<br> 
                started: {{ round.started }}  <br>
                completed: {{ round.completed }}
            </p> -->
        </router-link>
    </div>

    <div v-else-if="deck" class="deck-list-item bg-red-400 hover:bg-red-500 center">
        <router-link :to="{ path: '/round-view', query: { deckuuid: deck.uuid  } }">
            <div :data-uuid="deck.uuid">
                {{ deck.name }}  <br>
            </div>
            <p class="text-sm">
                No Round Found For This Deck
            </p>
        </router-link>
    </div>

    <div v-else class="deck-list-item red center"> 
        NO CARDS FOUND 
    </div>
   

</template>

<script>
export default {
    name: "DeckListItem"
};
</script>





<style>
.deck-list-item {
    /* border: 1px solid #ccc; */
    /* padding: 1rem; */
    margin-bottom: 1rem;
    margin-top: 1rem;
    /* border-top: 10px; */
    text-decoration: none;
    color: black;

    width: 80%;
    /* padding: 30px; */
    border-radius: 10px;
}

.deck-list-item > a {
    padding: 30px;

    text-decoration: none;
    color: black;

    width: 100%;
    height: 100%;
}

.deck-list-item * {
    font-size: x-large;
    font-weight: 700;
}

</style>