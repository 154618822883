<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    deck: Object,
})


</script>




<template>
    <div v-if="deck" class="deck">
        
        <h1>DECK INFO</h1>

        <h3>Deck Name: {{ props.deck?.name }}</h3>
        <h3>Deck Size: {{ props.deck?.deckSize }}</h3>
        
    </div>
    <div v-else>
        DECK NOT DEFINED
    </div>
</template>

<script>
export default {
    name: "DeckInfo"
};
</script>





<style>
.deck {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}
</style>