
<script setup>

import { onMounted, ref } from 'vue';
import { useTemplateRef } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

import { api } from '@/modules/api';
import { RoundContexts, RoundImages, Rounds } from '@/modules/Rounds';


const router = useRouter();

/* 
DEFINITIONS
*/
// const play = {
//     route: useRoute(),
//     rounduuid: this.route.query.rounduuid,
//     maxCardIndex: 0,
// }
const route = useRoute();
let rounduuid = route.query.rounduuid;
let cardindex = Number(route.query.cardindex);

let roundIsCompleted = ref(0);



/** @type {roundContext} */
let roundContext = RoundContexts.find(_roundContext => _roundContext.round?.uuid === rounduuid);
/** READ ONLY - Copy of reactive comonent! */
let round = roundContext.round;
let card = roundContext.cardGuessContexts[cardindex].card;
let imageUrl = ref( RoundImages.imageMap.get(card.uuid) );

let cardImageContext = Rounds.getCardImageContext(card.uuid);
console.log("cardImageContext =", cardImageContext);
if(!cardImageContext){
    console.warn("'cardImageContext' not loaded"); 
} 



let maxCardIndex = Number(roundContext.deck.deckSize-1); 
// play.maxCardIndex = Number(roundContext.deck.deckSize-1);

let answerStartTime;
let answerEndTime;
let answerTotalTime;

let correctDomFlag = ref(false)
let answeredIndex = ref(0)





onMounted(() => {
    console.log("MOUNTED");
    
    // renderCanvas();
})


// async function DEV_reloadImages(){

//     let cardImageContextRequests = [];
//     for(const cardGuessContext of roundContext.cardGuessContexts){
//         const imageContextRequest = Rounds.loadCardImageContext(cardGuessContext.card);
//         cardImageContextRequests.push(imageContextRequest)
//     }
//     // Trigger ref when all images are fetched
//     Promise.all(cardImageContextRequests).then(() => {
//         console.log("ALL IMAGES LAODED");
//         console.log("RoundImages.imageMap =", RoundImages.imageMap);
//         imageUrl.value = RoundImages.imageMap.get(card.uuid);
//         // imagesLoaded.value = 1;
//     })

    
// }

/* 
    Card loading checks
*/
// If the images are not loaded we need to return to the overview for loading
if(!RoundImages.imageMap.get(card.uuid)){
    console.warn("Image has not been loaded. Returning to round overview.");
    router.push(`/round-view?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`)

    // DEV DEV DEV
    console.warn("RELOADING ALL IMAGES : DEV MODE !")
    // DEV_reloadImages();
}



// Make sure user does not return to, or skip a card
let answeredCardsCount = Rounds.cardsAnswered(round.uuid);
if(answeredCardsCount !== cardindex){
    console.warn("Wrong card - Redirecting to overview!");
    router.push(`/round-view?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`)
}

// Start Round
if(cardindex === 0){
    api.rounds.patch(rounduuid, {
        "started": 1,
    })
    roundContext.round.started = 1;
}






/** Reset/change all card specific variables for the loading of a new card */
function loadNewCard(){
    // rounduuid = route.query.rounduuid;
    // cardindex = Number(route.query.cardindex);

    answerEndTime = 0;
    answerTotalTime = 0;
    answerStartTime = Date.now();

    correctDomFlag.value = false;
    answeredIndex.value = 0;

    // roundContext = FrontDb.state.deckRounds.find(deckround => deckround.round?.uuid === rounduuid);
    roundContext = RoundContexts.find(_roundContext => _roundContext.round?.uuid === rounduuid);
    round = roundContext.round;
    card = roundContext.cardGuessContexts[cardindex].card;
    imageUrl.value = RoundImages.imageMap.get(card.uuid);
    cardImageContext = Rounds.getCardImageContext(card.uuid);
    // updateCanvas();
    initCanvas();
    // cardGuess = roundContext.cardGuessContexts[cardindex].cardGuess;


    // cardImagePathString = `/api/images/${ roundContext.cardGuessContexts[cardindex].card.cardImageUuid }?file=true`;
    // _cardImagePath.value = cardImagePathString;
}

function exitRound(){
    let roundViewRoute = `/round-view?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`
    router.push(roundViewRoute)
}

function viewResults(){
    let roundResultsRoute = `/round-results?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`
    router.push(roundResultsRoute)
}

async function nextCard(){

    // Makes sure no rendered canvas is transfered nor the next image is rendered at too high a resolution
    stopRendering();
    clearCanvas();

    cardindex++;

    let nextRoute = "/round-play?" + `rounduuid=${rounduuid}` + `&cardindex=${cardindex}`;
    router.push(nextRoute)

    loadNewCard();
}


async function answer_click(event){
    // console.log("event.target = ", event.target)
    if(roundContext.cardGuessContexts[cardindex].cardGuess.is_done){
        console.warn("Only one answer per card is allowed!");
        return;
    }

    answerEndTime = Date.now();
    answerTotalTime = answerEndTime - answerStartTime;

    let cardAnswerUuid = roundContext.cardGuessContexts[cardindex].cardGuess.uuid;

    let correctAnswer = Number(roundContext.cardGuessContexts[cardindex].card.correct_answer);
    let userAnswer = null;
    let answeredCorrect = false; 

    // console.log("_deckRound =", _deckRound)
    console.log("cardGuess uuid =", correctAnswer);
    console.log("correctAnswer =", correctAnswer);
    console.log("event.target.id = ", event.target.id)



    switch (event.target.id) {
        case "answer_1":
            userAnswer = 1;
            answeredIndex.value = 1;
            break;

        case "answer_2":
            userAnswer = 2;
            answeredIndex.value = 2;
            break;

        case "answer_3":
            userAnswer = 3;
            answeredIndex.value = 3;
            break;

        case "answer_4":
            userAnswer = 4;
            answeredIndex.value = 4;
            break;
    
        default:
            console.error("Unknown user answer.")
            break;
    }

    if(userAnswer === correctAnswer){
        answeredCorrect = true;
        correctDomFlag.value = 1;
    }

    // console.log("answeredCorrect =", answeredCorrect)



    // UPDATE CARD-ANSWER
    api.cardGuess.patch(cardAnswerUuid, {
        "is_done": 1,
        "guess_choice": userAnswer,
        "guess_count": 1,
        "timeMs": answerTotalTime
    }).catch(error => {console.error(error)});

    roundContext.cardGuessContexts[cardindex].cardGuess.is_done = 1;
    roundContext.cardGuessContexts[cardindex].cardGuess.guess_choice = userAnswer;
    roundContext.cardGuessContexts[cardindex].cardGuess.guess_count = 1;
    roundContext.cardGuessContexts[cardindex].cardGuess.timeMs = answerTotalTime;
    console.log("cardGuess updated");
    

    // UPDATE ROUND
    let _completed;
    if(cardindex === maxCardIndex){
        _completed = 1;
        roundIsCompleted.value = 1;
    }
    else{
        _completed = 0
    }
    // cardindex === maxCardIndex ? _completed = 1 : _completed = 0;
    

    let newRoundTime = round.totalTimeMs + answerTotalTime;
    let newGuessCount = Number(round.guess_count) + 1;

    api.rounds.patch(rounduuid, {
            "completed": _completed,
            "started": 1,
            "guess_count": newGuessCount,
            "totalTimeMs": newRoundTime
        }).catch(error => {console.error(error)});

    roundContext.round.completed = _completed;
    roundContext.round.started = 1;
    roundContext.round.guess_count = newGuessCount;
    roundContext.round.totalTimeMs = newRoundTime;
    console.log("round updated");
}

async function imgLoaded(){
    console.log("IMAGE LOADED and timer started!")

    // At this point the user has started the card
    api.cardGuess.patch(roundContext.cardGuessContexts[cardindex].cardGuess.uuid, {
        "guess_choice": 1000
    }).catch(error => {console.error(error)});
    roundContext.cardGuessContexts[cardindex].cardGuess.guess_choice = 1000;

    answerStartTime = Date.now()
    // stopRendering();
    // clearCanvas();
    initCanvas();
    // updateCanvas(); // this will trigger 
    startRendering();
}


// CANVAS
let viewportHeight = window.innerHeight;

let canvasHeight = ref(viewportHeight*0.5);
// let canvasWidth;
const canvasRender = useTemplateRef('canvasRender')
/** @type {CanvasRenderingContext2D} */
let ctxRender;
const canvasOriginal = useTemplateRef('canvasOriginal')
let ctxOriginal;
console.log("canvasElement =", canvasRender);
// Original image data
// Access raw pixel data
let imageData;
let pixels;

function initCanvas(){
    ctxRender = canvasRender.value.getContext('2d');
    ctxOriginal = canvasOriginal.value.getContext('2d');

    if(ctxOriginal){ 
        ctxOriginal.drawImage(cardImageContext.image, 0, 0, cardImageContext.imageWidth, cardImageContext.imageHeight);
        imageData = ctxOriginal.getImageData(0, 0, cardImageContext.imageWidth, cardImageContext.imageHeight);
        pixels = imageData.data;
    }
    else {
        console.error("Unable to load image into canvas. Returning to overview");
        exitRound();
    }

}

let heightCount;
let heightCounts;
// let heightCounts = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32];
// let heightCounts = Array.from({length: 20}, (_, i) => 1 + i + i*3)
// console.log("heightCounts =", heightCounts)
let heightCountIndex = 0;

// for (let i = 1; i < 1000000; i++) {
//   window.clearInterval(i);
// }
let renderingInterval;



// console.log("renderingInterval =", renderingInterval);
// Clear any timeout/interval up to that id

// console.log("RESET"); 


function startRendering(){
    // heightCounts = Array.from({length: 15}, (_, i) => 1 + i + i*3)
    heightCounts = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 50, 60, 70, 80, 90, 100];
    heightCountIndex = 0;


    renderingInterval = setInterval(() => {
        updateCanvas();
    }, 1000)
}

function stopRendering(){
    if(renderingInterval){
        clearInterval(renderingInterval)
    }
}


function clearCanvas(){
    if(ctxRender){
        ctxRender.clearRect(0, 0, canvasHeight.value / cardImageContext.imageHWRatio, canvasHeight.value); 
    }
}

function drawImageOnCanvas(){
    clearCanvas();
    ctxRender.drawImage(cardImageContext.image, 0, 0, canvasHeight.value / cardImageContext.imageHWRatio ,canvasHeight.value)
}

function updateCanvas(){
    console.log("Updating canvas");

    heightCountIndex++;
    if(heightCountIndex >= heightCounts.length-1){
        stopRendering();
        drawImageOnCanvas();
        return;
    }
    heightCount = heightCounts[heightCountIndex];

    // heightCount = heightCount + 5;
    // if(heightCount > 200) {clearInterval(renderingInterval)}

    const beadHeight = Math.floor(canvasHeight.value / heightCount);
    const beadWidth = beadHeight;
    const widthCount = Math.floor((canvasHeight.value / cardImageContext.imageHWRatio) / beadWidth);

    let realBeadHeight = Math.floor(cardImageContext.imageHeight / heightCount);
    let realBeadWidth = realBeadHeight;

    let randInt1 = Math.random();
    
     
    if (ctxRender) {
        ctxRender.clearRect(0, 0, canvasHeight.value / cardImageContext.imageHWRatio, canvasHeight.value); 
        
        for(let i = 0; i < heightCount; i++){
            for(let j = 0; j < widthCount; j++){
                
                let realY = realBeadHeight * i;
                let realX = realBeadWidth * j;
                let pixelLocation = 4 * cardImageContext.imageWidth * realY + 4 * realX;

                // let pixelLocation = 4 * beadWidth  * j +  4 * heightCount * cardImageContext.imageWidth * i;
                let r = pixels[pixelLocation + 0];
                let g = pixels[pixelLocation + 1];
                let b = pixels[pixelLocation + 2];
                let a = pixels[pixelLocation + 3];
                ctxRender.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;

                ctxRender.fillRect(j*beadWidth, i*beadHeight, beadWidth, beadHeight)

            }
        }

        // ctxRender.fillStyle = randRgb;
        // ctxRender.fillRect(0,0, 10, 10)
    }
    else {
        console.error("Danvas rendering context is not detected. Returning to overview");
        exitRound();
    }
}


</script>




<template>
    <div class="RoundPlay">
        


        <div>
            
            
            <div v-if="imageUrl" id="card-image-container" class="center no-disp">
                <img @load="imgLoaded" id="card-image" :src="imageUrl" alt="IMAGE NOT FOUND">
            </div>
            <div v-else id="card-image-container" class="center">
                No image loaded into memory
            </div>

            <div v-if="imageUrl" id="canvas-container" class="center">
                <canvas ref="canvasRender" :width="canvasHeight/cardImageContext.imageHWRatio" :height="canvasHeight" class="canvas"></canvas>
                <canvas ref="canvasOriginal" :width="cardImageContext.imageWidth" :height="cardImageContext.imageHeight" class="bg-red-500" style="display: none;"></canvas>
            </div>
            


            <div id="four-answer-container" class="grid grid-cols-2 gap-5 mt-5">

            <div v-if="answeredIndex === 1 && correctDomFlag" class="answer correct" id="answer_1" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_1 }} </div>
            <div v-else-if="answeredIndex === 1 && !correctDomFlag" class="answer incorrect" id="answer_1" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_1 }} </div>
            <div v-else class="answer" id="answer_1" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_1 }} </div>

            <div v-if="answeredIndex === 2 && correctDomFlag" class="answer correct" id="answer_2" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_2 }} </div>
            <div v-else-if="answeredIndex === 2 && !correctDomFlag" class="answer incorrect" id="answer_2" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_2 }} </div>
            <div v-else class="answer" id="answer_2" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_2 }} </div>
            
            <div v-if="answeredIndex === 3 && correctDomFlag" class="answer correct" id="answer_3" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_3 }} </div>
            <div v-else-if="answeredIndex === 3 && !correctDomFlag" class="answer incorrect" id="answer_3" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_3 }} </div>
            <div v-else class="answer" id="answer_3" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_3 }} </div>

            <div v-if="answeredIndex === 4 && correctDomFlag" class="answer correct" id="answer_4" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_4 }} </div>
            <div v-else-if="answeredIndex === 4 && !correctDomFlag" class="answer incorrect" id="answer_4" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_4 }} </div>
            <div v-else class="answer" id="answer_4" @click="answer_click"> {{ roundContext.cardGuessContexts[cardindex].card.answer_4 }} </div>

            <!-- <div id="answer_2" @click="answer_click"> Answer 2 :  {{ selectedDeckRound.cardAndGuess[cardindex].card.answer_2 }} </div>
            <div id="answer_3" @click="answer_click"> Answer 3 :  {{ selectedDeckRound.cardAndGuess[cardindex].card.answer_3 }} </div>
            <div id="answer_4" @click="answer_click"> Answer 4 :  {{ selectedDeckRound.cardAndGuess[cardindex].card.answer_4 }} </div> -->

            </div>
            
        </div>
    </div>


        <div v-if="roundIsCompleted" class="nav-container">

            <div class="next-card-btn orange" @click="viewResults">
                RESULTS
            </div>

        </div>
        <div v-else class="nav-container">

            <div v-if="answeredIndex !== 0" class="next-card-btn orange" @click="exitRound">
                EXIT ROUND
            </div>
            <div v-else @click="exitRound">
                EXIT
            </div>

            <div v-if="answeredIndex !== 0" class="next-card-btn orange" @click="nextCard">
                NEXT CARD
            </div>
            <div v-else @click="nextCard">
                NEXT
            </div>

        </div>


</template>



<script>




</script>





<style scoped>
.no-disp {
    display: none;
}

.RoundPlay {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.next-card-btn {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
}

.answer {
    background-color: orange;
    border-radius: 5px;
    padding: 15px;
}
.answer:hover {
    cursor: pointer;
}
.answer.correct {
    background-color: rgb(51, 255, 0);
}
.answer.incorrect {
    background-color: rgb(214, 5, 5);
}


#card-image-container {
    height: 150px;
    width: var(--max-imges-width);
    max-height: 50vh;
    max-width: var(--max-imges-width);
    overflow: hidden;
}
#card-image {
    max-height: 100%;
    /* max-width: 100%; */
}


#canvas-container {
    /* height: 450px; */
    /* height: 80vh; */
    width: 100%;
    /* max-height: 50vh; */
    max-width: var(--max-imges-width);
    overflow: hidden;
    border: solid black 1px;
}

.canvas {
    background-color: transparent;
}


</style>