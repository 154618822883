<script setup>

import { useRouter } from 'vue-router';

// import DeckListItem from './DeckListItem.vue';
// import { FrontDb } from '@/modules/FrontDb';

import { RoundContexts } from '@/modules/Rounds';
import { onMounted, ref } from 'vue';
import { api } from '@/modules/api';
import { User } from '@/modules/User';


const router = useRouter();
// let route = useRoute();


// let firstRoundContext = RoundContexts.find(_roundContext => _roundContext?.deck
let availableRoundContexts = RoundContexts.length;
let firstRoundContext;
let deckuuid = ref(0);
let highscore = ref({});
let userpos = ref(0);
let mainHighscoreDataOk = ref(0);

console.log("availableRoundContexts =", availableRoundContexts);
console.log("RoundContexts[0] =", RoundContexts[0]);
console.log("RoundContexts[0].deck =", RoundContexts[0].deck);

if(availableRoundContexts > 0 && RoundContexts[0].deck){
    firstRoundContext = RoundContexts[0];
    router.push(`/highscore?deckuuid=${firstRoundContext.deck.uuid}`)
    // deckuuid = route.query.deckuuid;
    deckuuid.value = firstRoundContext.deck.uuid;
    console.log("deckuuid =", deckuuid.value);    
}


console.log("deckuuid =", deckuuid.value);

onMounted( async () => {
   
    if(deckuuid.value){
        console.log("LOAD HIGHSCORE for ", deckuuid.value)
        api.highscore.getHighscore(deckuuid.value, User.state.username, 0)
        .then((_highscore) => {
            highscore.value = _highscore
            userpos.value = _highscore.userpos;
            // console.log("_highscore =", _highscore)
            
            mainHighscoreDataOk.value = 1;
        })
    }
})

function newDeck(event) {
    // console.log("event.target.getAttribute('deckuuid') =", event.target.getAttribute('deckuuid'));
    const _deckuuid = event.target.getAttribute('deckuuid');
    if(_deckuuid){
        router.push(`/highscore?deckuuid=${_deckuuid}`)
        deckuuid.value = _deckuuid;
        api.highscore.getHighscore(_deckuuid, User.state.username, 0)
        .then((_highscore) => {
            highscore.value = _highscore
            userpos.value = _highscore.userpos;
            // console.log("_highscore =", _highscore)
            
            mainHighscoreDataOk.value = 1;
        })
    }
    
}


function backtoDashboard(){
    router.push("/dashboard-user");
}


</script>



<template>

    <!-- <LoadingScreen />  -->

    <div class="highscore-container start col">


        <div @click="backtoDashboard" class="bg-gray-500 rounded-md w-1/4 p-2 hover:cursor-pointer">BACK</div>

        <h1 class="text-xl text-black font-bold m-3">HIGHSCORE</h1>



        <div v-if="RoundContexts.length > 0" class="highscore-deck-list center row">

                <div v-for="(roundContext, index) in RoundContexts" :key="index" class="w-100 center">

                    <div v-if="deckuuid == roundContext.deck.uuid" class="highscore-current-deck-label">
                        {{ roundContext.deck.name }}
                    </div>
                    <div v-else-if="roundContext.round && roundContext.deck" @click="newDeck" :deckuuid="roundContext.deck.uuid" class="highscore-deck-label">
                        {{ roundContext.deck.name }}
                    </div>
                    <div v-else> Deck Not Found</div>

                    <!-- <DeckListItem v-if="roundContext.round && roundContext.deck" :name="roundContext.deck.name" :uuid="roundContext.deck.uuid" :deck="roundContext.deck" :round="roundContext.round" />
                    <div v-else-if="roundContext.deck"> deckRound.round missing</div>
                    <div v-else-if="roundContext.round"> deckRound.deck missing</div>
                    <div v-else> Unable to display decks. deckRound.deck AND deckRound.round missing</div> -->

                </div>
                <!-- {{ FrontDb.state.deckRounds[1] }} -->
        </div>
        <div v-else>
            NO CARDS LOADED
        </div>





        <div v-if="mainHighscoreDataOk" class="highscore-main start col">

                <div class="highscore-row evenly row">
                    <div class="highscore-row-label"> rank </div>
                    <div class="highscore-row-label">username</div>
                    <div class="highscore-row-label"> time</div>
                </div>

                <div class="w-100 flex-grow vert-scroll">

                    <div v-for="(highscoreRow, index) in highscore.highscore" :key="index" class="w-100 highscore-row evenly row">
    
                        <!-- <div v-if="highscoreRow" class="highscore-row center row"> -->
                            
                            <div class="highscore-row-label">{{ index + 1  + "."}}</div>
                            <div class="highscore-row-label">{{ highscoreRow.name }}</div>
                            <div class="highscore-row-label">{{ highscoreRow.totalTimeMs / 1000 }}</div>
    
                    </div>

                </div>

                <div class="user-highscore-box w-100">
                    <h1 class="user-highscore-title mt-10">YOUR RANK</h1>
                    <div class="highscore-row evenly row">
                        <div class="highscore-row-label"> {{ userpos === 0 ? " - " : userpos}} </div>
                        <div class="highscore-row-label"> {{ User.state.username }}</div>
                        <div class="highscore-row-label"> {{ (RoundContexts.find(_roundContext => _roundContext.deck.uuid === deckuuid)).round.totalTimeMs }} </div>
                    </div>
                </div>

                <!-- {{ FrontDb.state.deckRounds[1] }} -->
        </div>
        <div v-else>
            LOADING MAIN HIGHSCORE
        </div>

        

    </div>

</template>




<style scoped>

.highscore-container {
    height: 100%;
    width: 100%;
    /* background-color: rgb(91, 151, 203); */
}

.highscore-main {
    width: 100%;
    height: 100%;
    max-height: 70vh;
}
.vert-scroll {
    overflow-y: scroll;
}

.highscore-deck-list {
    border: solid rgb(45, 16, 16) 1px;
    height: 10%;
    width: 100%;
    justify-content: center;
}

.highscore-deck-label {
    cursor: pointer;
    color: orange;
}
.highscore-current-deck-label {
    cursor: pointer;
    color: rgb(168, 95, 11);
}

.user-highscore-box {
    margin-top: 2em;
    border: solid black 1px;
}
.user-highscore-title {
    font-size: x-large;
    margin: 20px;
}

.highscore-row {
    /* background-color: aliceblue; */
    width: 100%;
    height: 5vh;
    min-height: 3rem;
    color: rgb(0, 0, 0);
    border-bottom: rgba(176, 176, 176, 0.608) solid 1px;
    margin: 5px;
}

.highscore-row-label {
    padding: 5px;
    font-size: large;
    /* margin-left: 5vw;
    margin-right: 5vw; */
}


</style>
