

<script setup>

// import { ref } from 'vue';
import { onMounted } from 'vue';
// import { computed } from 'vue';
import { ref } from 'vue';
// import { useRouter } from 'vue-router';

// import { baseApiUrl, newRound } from '@/modules/api';

// import DeckInfo from './DeckInfo.vue';
// import RoundInfo from './RoundInfo.vue';

// import { User } from '@/modules/User';
// import RoundInfo from './RoundInfo.vue';

// import { FrontDb } from '@/modules/FrontDb';



import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
// import { api } from '@/modules/api';
import { CardImageContexts, RoundContexts, RoundImages, Rounds } from '@/modules/Rounds';
const router = useRouter();
const route = useRoute();
const deckuuid = route.query.deckuuid;
const rounduuid = route.query.rounduuid;
console.log("deckuuid =", deckuuid)
console.log("rounduuid =", rounduuid)


let roundContext = RoundContexts.find(_roundContext => _roundContext.deck?.uuid === deckuuid);
console.log("roundContext.deck =", roundContext.deck);


// Load images
const imagesLoaded = ref(0);
RoundImages.imageMap.clear();
console.log("RoundImages.imageMap =", RoundImages.imageMap);



// Make sure round is properly loaded
if(!roundContext || !roundContext.cardGuessContexts || !roundContext.cardGuessContexts[0] || !roundContext.cardGuessContexts[0].card){
    console.warn("Roundcontext not fully available. Returning to round overview.");
    router.push(`/round-view?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`)
}



// If round is not completed we load all images
// if(!roundContext.round.completed){
    // let cardImageContextRequests = [];
    // for(const cardGuessContext of roundContext.cardGuessContexts){
    //     const imageContextRequest = Rounds.loadCardImageContext(cardGuessContext.card);
    //     cardImageContextRequests.push(imageContextRequest)
    // }
    // // Trigger ref when all images are fetched
    // Promise.all(cardImageContextRequests).then(() => {
    //     console.log("ALL IMAGES LAODED");
    //     console.log("RoundImages.imageMap =", RoundImages.imageMap);
        
    //     imagesLoaded.value = 1;
    // })
// }
// else {
    imagesLoaded.value = 1;
// }






let cardImageContext = Rounds.getCardImageContext(roundContext.cardGuessContexts[0].card.uuid);
onMounted(() => {

    // Make sure images are properly loaded
    if(!cardImageContext){
    console.warn("Image has not been loaded? Returning to round overview.");
    router.push(`/round-view?rounduuid=${rounduuid}&deckuuid=${roundContext.deck.uuid}`)
}

})


function backToRoundView(){
    router.push(`/round-view?rounduuid=${rounduuid}&deckuuid=${deckuuid}`);
}





// let completedCardCount = _deckRound.cardAndGuess[cardindex].card.cardImageUuid

// const selectedRoundContext = computed(() =>
//     // FrontDb.state.deckRounds.find(deckround => deckround.deck?.uuid === deckuuid)
//     RoundContexts.find(_roundContext => _roundContext.deck?.uuid === deckuuid)
// );
// const selectedRound = computed(() =>
//     FrontDb.state.deckRounds.find(deckround => deckround.round?.uuid === rounduuid)
// );
// console.log("roundContext =", roundContext);
// console.log("CardImageContexts =", CardImageContexts);

</script>



<template>
    <div class="DeckOverview">
        
        <div @click="backToRoundView" class="bg-gray-500 rounded-md w-1/4 p-2 hover:cursor-pointer">BACK</div>
        <h1 class="text-xl">ROUND RESULTS</h1>
            

                <div v-if="cardImageContext && roundContext.cardGuessContexts.length > 0" class="results-list-container border-2-slate-300">

                    <div class="scrollable-list">

                        <div v-for="(cardGuessContext, index) in roundContext.cardGuessContexts" :key="index" class="result-card w-100 center">
    
                            <div class="small-img-container">
                                <img :src="CardImageContexts[index].imageUrl" alt="image not found" class="small-image">
                            </div>
    
                            <!-- TIME -->
                            <div v-if="cardGuessContext.cardGuess.guess_choice === cardGuessContext.card.correct_answer" class="time-div center text-3xl correct text-white">
                                {{ cardGuessContext.cardGuess.timeMs / 1000 }}
                            </div>
                            <div v-else class="time-div center text-3xl incorrect text-white">
                                {{ cardGuessContext.cardGuess.timeMs / 1000 }}
                            </div>
    
                        </div>
                    </div>
                    <div class="center mt-8 mb">
                        <div class="text-xl">
                                TOTAL TIME
                        </div>
                        <div class="time-div total-div center text-3xl bg-blue-600 text-white">
                            {{ roundContext.round.totalTimeMs / 1000 }}
                        </div>
                    </div>
                   

                </div>
                <div v-else>
                    LOADING RESULTS
                </div>
                

            
        
    
    </div>
</template>






<style>
.results-list-container {
    max-height: 60vh;
    min-height: 60vh;
}
.scrollable-list {
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: scroll;
}

.result-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.time-div {
    height: 15vh;
    width: 30%;
    border-radius: 5px;
}
.total-div {
    max-height: 10vh;
}
.correct {
    background-color: rgba(19, 63, 15, 0.861);
}
.incorrect {
    background-color: rgb(89, 15, 10);
}

.small-img-container {
    margin: 10px;
    max-height: 15vh;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10%;
    /* background-color: aliceblue; */
}
.small-image {
    max-height: 15vh;
}

.DeckOverview {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

#start-deck-btn {
    padding: 10px;
}
#start-deck-btn:hover {
    cursor: pointer;
}
</style>