<script setup>
// import { RoundImages } from '@/modules/Rounds';
// import { defineProps } from 'vue';

// const props = defineProps({
//     round: Object,
//     cardGuessContexts: Array
// })


</script>




<template>
    <div class="round">
        
        <!-- <h1>ROUND INFO</h1> -->
         <h1>Cards Overview</h1>
        <div v-if="round">

            <!-- <h3 class="text-black">Started: {{ props.round.started }}</h3>
            <h3>Completed: {{ props.round.completed }}</h3>
            <h3>Guess Count: {{ props.round.guess_count }}</h3>
            <h3>Total Time: {{ props.round.totalTimeMs }}</h3> -->

            <div class="grid grid-cols-5 gap-2 mt-5">

                <div v-for="cardGuessContext in cardGuessContexts" :key="cardGuessContext.card.uuid">

                    <div v-if="cardGuessContext && cardGuessContext.cardGuess" >
                        
                        <div v-if="cardGuessContext.cardGuess.is_done && (cardGuessContext.card.correct_answer === cardGuessContext.cardGuess.guess_choice)" class="bg-green-300 h-12 w-12">
                            <!-- <img :src="RoundImages.imageMap.get(cardGuessContext.card.uuid)" alt="" class="image-icon"> -->
                            <!-- <p>Answer: {{ cardGuessContext.cardGuess.guess_choice }}</p> -->
                            <p>{{ cardGuessContext.cardGuess.timeMs }} ms</p>
                        </div>
                        <div v-else-if="cardGuessContext.cardGuess.is_done" class="bg-red-300 h-12 w-12">
                            <p>{{ cardGuessContext.cardGuess.timeMs }} ms</p>
                        </div>
                        <div v-else  class="bg-gray-300 h-12 w-12"></div>
                        <!-- <div class="bg-green-100 h-10 w-10">

                            {{ _cardAndGuess.card.name }} ...  is_done = {{ _cardAndGuess.cardGuess.is_done }} ... deckIndex = {{ _cardAndGuess.card.deckIndex }}

                        </div> -->
                    </div>
                    <div v-else>
                        LOADING CARD
                    </div>

                </div>

            </div>
            

        </div>
        <div v-else>
            NO ROUND DETECTED
        </div>
    
    </div>
</template>

<script>
export default {
    name: "RoundInfo"
};
</script>





<style>
.round {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

.image-icon {
    max-height: 100%;
    max-width: 100%;
}

</style>
