<script setup>
import { onMounted } from 'vue'
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { User } from '@/modules/User';
// import { FrontDb } from '@/modules/FrontDb';
import { Imges } from '@/modules/Imges';
// import * as ReactivityTest from '@/modules/reactivityTest';
import { api } from '@/modules/api';
import { Rounds } from '@/modules/Rounds';

const router = useRouter();


const loadingNewGuest = ref(0);

 
onMounted( async () => {

    let _isLoggedIn = await User.isLoggedIn();
    // console.log("_isLoggedIn =", _isLoggedIn)

    if(_isLoggedIn){
        router.push('/dashboard-user');
    }
     
    
})


async function click_play_as_guest() {

        loadingNewGuest.value = 1;

        let newGuestOk = await Imges.newGuestUser();

        if(!newGuestOk){
            console.error("New guest creation failed")
            return;
        }

        let isLoggedIn = await Imges.isLoggedIn();
        if(!isLoggedIn){
            console.log("Failed to verify login for new guest.")
            return;
        }


        // while(FrontDb.state.deckRounds.length > 0){
        //     FrontDb.state.deckRounds.pop();
        // }

        // let allDeckRounds = await Imges.createAllDeckRounds(User.state.userUuid);
        // if(!allDeckRounds){
        //     console.log("DATA NOT CREATED OK FOR NEW GUEST")
        //     return;
        // }
        // console.log("allDeckRounds =", allDeckRounds)

        let _roundContexts = await api.roundcontext.postForUser(User.state.userUuid);
        Rounds.load(_roundContexts);

        
        // let dataLoadedOk = await Imges.loadAppData();
        // if(!dataLoadedOk){
        //     console.log("DATA NOT LOADED OK FOR NEW GUEST")
        //     return;
        // }
        
        // Replaced the above Imges-call to retain reactive context
        // await FrontDb.loadDeckCards();
        // await FrontDb.loadRoundsGuesses(FrontDb.state.user.getUseruuid());

        router.push('/dashboard-user');
    }


// function addToReactiveObject(){
//     // roundContexts.push("a")
//     ReactivityTest.addC();
// }
// function clearReactiveObject(){
//     ReactivityTest.clear();
// }

</script>




<template>


  <div class="home">

    <img id="home-graffiti" class="center" alt="Vue logo" src="../assets/imgess-graffiti.png">

    <div id="home-but-container">
        
        <router-link to="/login">
            <button id="sign-in" class="btn btn-home green">SIGN IN</button>
        </router-link>
        <!-- <div>
            <h1>{{ ReactivityTest.reactiveObject }}</h1>
            <h1 class="border" @click="addToReactiveObject"> ADD </h1>
            <h1 class="border" @click="clearReactiveObject"> CLEAR </h1>
        </div> -->
        
        <button v-if="loadingNewGuest === 0" id="play-guest" class="btn btn-home transparent" @click="click_play_as_guest">PLAY AS GUEST</button>
        <div v-else>GENERATING NEW GUEST USER</div>
        
        <router-link to="/create-user">
            <button id="create-account" class="btn btn-home transparent">CREATE ACCOUNT</button>
        </router-link>
    
    </div>
    
  </div>

</template>


<script>
// console.log("process.env.API_BASE_URL =", process.env.API_BASE_URL)


// eslint-disable-next-line
function click_now(event){
    console.log("ALSKFJASL DLAS")
}

export default {
  name: 'HomeMenu',
  props: {
    msg: String
  },
  methods: {
    click_now() {
        console.log("HELLO ?")
        // window.location.replace(  
        //     "/login",
        // );
        // history.pushState({}, "", "/about");
        this.$router.push('/about');
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
    height: inherit;
    width: 100%;
}

#home-graffiti {
    /* width: 120%; */
    height: 40%;
    position: absolute; 
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* left: calc(100cqw); */


    /* background-color: #2c3e50; */
    z-index: 0;
    /* display: none; */
} 

#home-but-container {
    position: relative;

    top: 30%;

    z-index: 1;

    height: 60%;
    width: 100%;
    

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
#home-but-container > * {
    width: 100%;
}

.home-links {
    width: 100%;
}

.btn-home {
    font-size: x-large;
    width: 80%;
    padding: 20px;
    border-radius: 15px;
}




</style>
