import { reactive } from 'vue';

import { api } from "./api";
import { User } from './User';



/**
 * 
 * @typedef CardAndGuess
 * @prop {Object} card An Imges db card object.
 * @prop {Object} cardGuess cardGuess associated with the card object. 
 * 
 * 
 * @typedef DeckRound Contains all Deck/round objects of the current user.
 * @prop {Object} deck Deck object. 
 * @prop {Object} round The user round that belongs to the deck.
 * @prop {Array<CardAndGuess>} cardAndGuess All card objects that belong to the deck.
 * 
 */

export class FrontDb {

    /** 
     * @typedef FrontState Contains all reactive app data
     * @prop {Array<DeckRound>} deckRounds
     * @prop {User} user
     * 
     */
    static state = reactive({
        deckRounds: [],
        user: User,
    });


    /**  clears all frontend app data */
    static clearFrontData(){
        while(this.state.deckRounds.length > 0){
            this.state.deckRounds.pop();
        }
        this.state.user.clearState();
    }

    static async initData(){

    }
    
    /**
     * Clears FrontDb data and loads decks and cards.
     */
    static async loadDeckCards(){
        // console.log("LOADING DECKS FRONT");

        
        // clear current array
        while (this.state.deckRounds.length) { this.state.deckRounds.pop(); }

        let _decks = await api.decks.getAll();

        // https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
        // If you want to read the files in sequence, you cannot use *forEach*

        // Create the deckcards and push to array
        for (const deck of _decks) {
            // console.log("deck =", deck);
            
            /** @type {DeckRound} */
            let deckRound = {};
            deckRound.cardAndGuess = [];
            
            deckRound.deck = deck;
            deckRound.round = undefined;

            const cards = await api.cards.getCardsOfDeck(deck.uuid);
            // deckRound.cardAndGuess.card = cards;

            cards.forEach(card => {
                /** @type {CardAndGuess} */
                let cardAndGuess = {};
                cardAndGuess.card = card;
                cardAndGuess.cardGuess = undefined;
                deckRound.cardAndGuess.push(cardAndGuess);
            })

            deck.cards = cards;
            
            
            this.state.deckRounds.push(deckRound);
        }

        // Create the deckcards and push to array
        // _decks.forEach(async (deck) => {
            // console.log("deck =", deck)

            // /** @type {DeckRound} */
            // let deckRound = {};
            // deckRound.cardAndGuess = [];
            
            // deckRound.deck = deck;
            // deckRound.round = undefined;

            // const cards = await api.cards.getCardsOfDeck(deck.uuid);
            // // deckRound.cardAndGuess.card = cards;

            // cards.forEach(card => {
            //     /** @type {CardAndGuess} */
            //     let cardAndGuess = {};
            //     cardAndGuess.card = card;
            //     cardAndGuess.cardGuess = undefined;
            //     deckRound.cardAndGuess.push(cardAndGuess);
            // })

            // deck.cards = cards;
            
            
            // this.state.deckRounds.push(deckRound);
            // console.log("cards =", cards)
            // console.log("this.deckRounds =", this.state.deckRounds)

        // })
        
    }

    static async loadRoundsGuesses(_userUuid){
        let _rounds = await api.rounds.getRoundsOfUser(_userUuid);
        
        // console.log("userRounds =", _userRounds)

        // https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
        // If you want to read the files in sequence, you cannot use *forEach*

        // 1. match round.deckuuid to the decks in this.deckRounds
        for (const round of _rounds){
            // console.log("round =", round)
            
            // find matching deckRound
            let deckRound = this.state.deckRounds.find((_deckround) => _deckround.deck.uuid === round.deckUuid)
            deckRound.round = round;

            // check that I actually update the element in the array and not a copy!
            // deckRound = this.deckRounds.find((_deckround) => _deckround.deck.uuid === round.deckUuid)
            // console.log("deckRound ?=", deckRound)

            let cardGuesses = await api.cardGuess.getGuessesOfRound(round.uuid);
            
            // 2. match guesses to cards in this.deckRounds.decksGuesses
            deckRound.cardAndGuess.forEach((cardAndGuess) => {
                cardAndGuess.cardGuess = cardGuesses.find(_cardGuess => _cardGuess.cardUuid === cardAndGuess.card.uuid)
            })

            // console.log("_cardGuesses =", cardGuesses)
            // console.log("this.deckRounds =", this.state.deckRounds)
        }


        // 1. match round.deckuuid to the decks in this.deckRounds
        // _userRounds.forEach( async (round) => {
        //     // console.log("round =", round)
            
        //     // find matching deckRound
        //     let deckRound = this.state.deckRounds.find((_deckround) => _deckround.deck.uuid === round.deckUuid)
        //     deckRound.round = round;

        //     // check that I actually update the element in the array and not a copy!
        //     // deckRound = this.deckRounds.find((_deckround) => _deckround.deck.uuid === round.deckUuid)
        //     // console.log("deckRound ?=", deckRound)

        //     let cardGuesses = await api.cardGuess.getGuessesOfRound(round.uuid);
            
        //     // 2. match guesses to cards in this.deckRounds.decksGuesses
        //     deckRound.cardAndGuess.forEach((cardAndGuess) => {
        //         cardAndGuess.cardGuess = cardGuesses.find(_cardGuess => _cardGuess.cardUuid === cardAndGuess.card.uuid)
        //     })

        //     // console.log("_cardGuesses =", cardGuesses)
        //     // console.log("this.deckRounds =", this.state.deckRounds)
        // })
            
    }

    /** Adds a deck round and placeholder cardAnswers for the current user */
    static async newRound(_deckUuid){
        
        // console.log("this.state.user.getUseruuid() =", this.state.user.getUseruuid())
        // console.log("_deckUuid =", _deckUuid)

        const _newRound = await api.rounds.post(this.state.user.getUseruuid(), _deckUuid);
        if(!_newRound){
            console.error("Unable to create new round");
            return;
        }
        // console.log("_newRound =", _newRound)
        

        const deckround = this.state.deckRounds.find(_deckRound => _deckRound.deck.uuid === _deckUuid);

        deckround.round = _newRound;
        console.log("deckround.round =", deckround.round)

        // Add all guesses
        for(let i = 0; i < deckround.deck.deckSize; i++){
            // console.log("ROUNDROUND UUID ==== ",deckround.round.uuid )
            let _newCardGuess = await this.newCardGuess(deckround.round.uuid, deckround.cardAndGuess[i].card.uuid);
            if(!_newRound){
                console.error(`Unable to create new cardguess for rounduuid = ${deckround.round.uuid} & carduuid = ${deckround.cardAndGuess[i].card.uuid}`);
                return;
            }
            else{
                deckround.cardAndGuess[i].cardGuess = _newCardGuess;
            }
            // let _cardAndGuess = deckround.cardAndGuess.find(_cardAndGuess => _cardAndGuess.card.uuid === _newCardGuess)
        }


    }

    /** Adds a deck round for the current user */
    static async newCardGuess(_rounduuid, _cardUuid){
        
        // console.log("this.state.user.getUseruuid() =", this.state.user.getUseruuid())
        // console.log("_deckUuid =", _deckUuid)

        const _newCardGuess = await api.cardGuess.post(_rounduuid, _cardUuid);
        // console.log("_newCardGuess =", _newCardGuess)

        return _newCardGuess;
        // const deckround = this.state.deckRounds.find(_deckRound => _deckRound.deck.uuid === _cardUuid);
        // const deckround = this.state.deckRounds.find(_deckRound => _deckRound.deck.uuid === _cardUuid);
        

        // deckround.round = _newRound;

    }


    
}


export const frontDb = new FrontDb();

