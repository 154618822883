import { createApp } from 'vue'
import App from './App.vue'

// import { FrontDb } from './modules/front-db';

// TESTING ROUTER 
import HelloWorld from './components/HelloWorld.vue'
import HomeMenu from './components/HomeMenu.vue';
import CreateUser from './components/CreateUser.vue';
import UserLogin from './components/UserLogin.vue';
import DashboardUser from './components/DashboardUser.vue';
import DeckList from './components/DeckList.vue';
import RoundPlay from './components/RoundPlay.vue';
import RoundView from './components/RoundView.vue';

import { createWebHistory, createRouter } from 'vue-router'
import './assets/tailwind.css'
import RoundResults from './components/RoundResults.vue';
import HighscoreView from './components/HighscoreView.vue';
const routes = [
    // RouteRecordRaw --- https://router.vuejs.org/api/#RouteRecordRaw
    { path: '/', component: HomeMenu },
    { path: '/login', component: UserLogin },
    { path: '/create-user', component: CreateUser },
    { path: '/dashboard-user', component: DashboardUser },
    { path: '/deck-list', component: DeckList },
    { path: '/round-view', component: RoundView},
    { path: '/round-play', component: RoundPlay },
    { path: '/round-results', component: RoundResults },
    { path: '/highscore', component: HighscoreView },

    { path: '/:pathMatch(.*)*', redirect: '/' },

    { path: '/about', component: HelloWorld },
]



const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  console.log(`Navigating to ${to.fullPath} from ${from.fullPath}`);

  next();
});



createApp(App)
    .use(router)
    .mount('#app')




// CRITICAL FOR IHPONE HEIGHT
function setBodyHeight() {
  const viewportHeight = window.innerHeight;
  document.body.style.height = `${viewportHeight}px`;
}
window.x = 1;
// var x = 1;
// console.log("x=", x)
// Call it on load and resize
window.addEventListener('load', setBodyHeight);
window.addEventListener('resize', setBodyHeight);