<script setup>

// import { RoundContexts } from '@/modules/Rounds';

import { Cookies } from '@/modules/cookie';
import { logout } from '@/modules/api';

console.log("Setup script")

let streak = User.state.username;


</script>




<template>

  <div class="dashboard">

    <button id="logout-btn" class="btn orange" @click="logout_click">LOGOUT</button>

    <img id="home-graffiti" class="center" alt="Vue logo" src="../assets/imgess-graffiti.png">

    <div id="home-but-container">

        <!-- <h1>DASHBOARD</h1> -->

        <router-link to="/deck-list">
            <button id="create-account" class="btn play-btn transparent">PLAY</button>
         </router-link>
         
         <!-- TODO: Remove this 'test info' -->
         <!-- <div v-if="RoundContexts.length > 0">
             {{ RoundContexts[0].cardGuessContexts[0] }}
         </div> -->

         <p v-if="streak">Streak = {{ streak }}</p>
         <p v-else>Streak = ?</p>

         <router-link to="/highscore">
            <button id="highscore-button" class="btn high-score-btn orange">HIGH SCORE</button>
         </router-link>


    </div>

  </div>
  
</template>


<script>

import { User } from '@/modules/User';
import { FrontDb } from '@/modules/FrontDb';

// import { RoundContexts } from '@/modules/Rounds';



export default {
  name: 'DashboardUser',
  props: {
    msg: String
  },
  // The setup-script above takes precedence 
  setup() {
    // DOESN"T PRINT if setup-script exists in component
    // console.log("Setup method") 
    
  },
  // This still runs despite a 'onMounted' method above
  mounted() {
    // console.log("MOUNTED 2") // 0
    // console.log("dashboard element =", document.querySelector(".dashboard"))
  },
  // No errors but was never able to run. Even when setup script and mounted-method was removed...
  onMounted() {
    // console.log("ON MOUNTED 2") // 0
    // console.log("dashboard element =", document.querySelector(".dashboard"))

  },
  methods: {
    click_now() {
        console.log("HELLO ?")
        // window.location.replace(
        //     "/login",
        // );
        // history.pushState({}, "", "/about");
        this.$router.push('/about');
    },

    async logout_click(){
        console.log("LOGOUT");

        // API
        let logoutOk = await logout();

        // 
        User.clearState();
        FrontDb.clearFrontData();
        // document.cookies.r
        Cookies.clear();

        console.log("logoutOk =", logoutOk);
        this.$router.push('/');
        
    }
  }
}
</script>





<style scoped>
.dashboard {
    height: inherit;
    width: 100%;
}

#logout-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10000;
}

#home-graffiti {
    /* width: 120%; */
    height: 40%;
    position: absolute; 
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    /* left: calc(100cqw); */


    /* background-color: #2c3e50; */
    z-index: 0;
    /* display: none; */
} 

#home-but-container {
    position: relative;

    top: 40%;

    z-index: 1;

    height: 60%;
    width: 100%;
    

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
#home-but-container > *{
    width: 100%;
}

.home-links {
    width: 100%;
}

.btn-dash {
    font-size: x-large;
    width: 80%;
    padding: 20px;
    border-radius: 15px;
}

.play-btn {
    font-size: 6vh;
    color: black;
}
.high-score-btn {
    font-size: xx-large;
}



</style>
