<script setup>
    import { onMounted } from 'vue';
    import { ref } from 'vue';
    import { nextTick } from 'vue';
    import { useRouter } from 'vue-router';
    
    
    // import DevMenu from './components/DevMenu.vue';
    import { User } from './modules/User';
    import { api, getUser } from './modules/api';
    import { Cookies } from './modules/cookie';
    // import { FrontDb } from './modules/FrontDb';
    import { RoundContexts, Rounds } from './modules/Rounds';

    let router = useRouter();

    const isInitializing = ref(true)
    


    onMounted( async () => {
        console.log("FULL APP RELOAD")

        // MAKE SURE USER 'LOGIN' IS VALIDATED ON *EVERY* FULL RELOAD
        let _isLoggedIn = await User.isLoggedIn();
        if(!_isLoggedIn){
            router.push("/");
            console.log("IN NOT LOGGED IN. RETURNING TO HOME.")
            
            setTimeout(() => {
                isInitializing.value = false;
            }, 100);
            return;
        }
        

        // // make ALL decks and their respective cards objects available
        // await FrontDb.loadDeckCards();
        // console.log("LOADED DECKS AND CARDS")

        // If the user is logged in we fetch and store the user info in state
        let _userUuid = Cookies.get("useruuid");
        let user = await getUser(_userUuid);
        User.setUser(user)
        console.log("LOGGED IN AS", User.getUsername());


        // Then load all rounds and card guesses for the user
        // await FrontDb.loadRoundsGuesses(_userUuid);
        // console.log("FETCHED ALL ROUNDS AND GUESSES");
        




        let _roundContexts = await api.roundcontext.getForUser(_userUuid);
        Rounds.load(_roundContexts);
        console.log("RoundContexts =", RoundContexts)

        console.log("APP LOADING DONE")

        // Intentional delay to make sure no rendering/errors/warnings take place before intialization
        setTimeout( async () => {
        //     isInitializing.value = false;
                await nextTick(); // Wait for Vue to process all updates -- Doesn't F*kn work
                isInitializing.value = false;
                
        }, 100);

       

    })  

</script>




<template>

    <!-- <DevMenu />  -->

    <div v-if="isInitializing" class="loading-state">

        <h1>Loading...</h1>

    </div>

    <RouterView v-else />

</template>




<script>

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // HomeMenu
  },
  methods: { 
    pop(){
        // this.$router.back()
    }
  }
}

</script>




<style>

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    
    /* margin: 10px; */
    height: 98%;
    width: 96%;
    max-width: 700px;
    min-width: 320px;
    box-shadow: 0 0 0 1px rgb(148, 148, 148);
    overflow: hidden;
    background-color: var(--imges-bg-white);
}

</style>
